import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField'; 
import '../App.css';
import * as PlatForm from 'platform';

class AutoCompleteMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      options: [],
      arrayOfAvaPostalCode: {
        "1": "Yes",
        "2": "Yes",
        "3": "Yes",
        "4": "Yes",
        "5": "Yes",
        "6": "Yes",
        "7": "Yes",
        "8": "Yes",
        "9": "Yes",
        "10": "Yes",
        "11": "Yes",
        "12": "Yes",
        "13": "Yes",
        "14": "Yes",
        "15": "Yes",
        "16": "Yes",
        "17": "Yes",
        "18": "Yes",
        "19": "Yes",
        "2A": "No",
        "2B": "No",
        "20": "No",
        "21": "Yes",
        "22": "Yes",
        "23": "Yes",
        "24": "Yes",
        "25": "Yes",
        "26": "Yes",
        "27": "Yes",
        "28": "Yes",
        "29": "Yes",
        "30": "Yes",
        "31": "Yes",
        "32": "Yes",
        "33": "Yes",
        "34": "Yes",
        "35": "Yes",
        "36": "Yes",
        "37": "Yes",
        "38": "Yes",
        "39": "Yes",
        "40": "Yes",
        "41": "Yes",
        "42": "Yes",
        "43": "Yes",
        "44": "Yes",
        "45": "Yes",
        "46": "Yes",
        "47": "Yes",
        "48": "Yes",
        "49": "Yes",
        "50": "Yes",
        "51": "Yes",
        "52": "Yes",
        "53": "Yes",
        "54": "Yes",
        "55": "Yes",
        "56": "Yes",
        "57": "Yes",
        "58": "Yes",
        "59": "Yes",
        "60": "Yes",
        "61": "Yes",
        "62": "Yes",
        "63": "Yes",
        "64": "Yes",
        "65": "Yes",
        "66": "Yes",
        "67": "Yes",
        "68": "Yes",
        "69": "Yes",
        "70": "Yes",
        "71": "Yes",
        "72": "Yes",
        "73": "Yes",
        "74": "Yes",
        "75": "Yes",
        "76": "Yes",
        "77": "Yes",
        "78": "Yes",
        "79": "Yes",
        "80": "Yes",
        "81": "Yes",
        "82": "Yes",
        "83": "Yes",
        "84": "Yes",
        "85": "Yes",
        "86": "Yes",
        "87": "Yes",
        "88": "Yes",
        "89": "Yes",
        "90": "Yes",
        "91": "Yes",
        "92": "Yes",
        "93": "Yes",
        "94": "Yes",
        "95": "Yes",
        "97": "No"
      },
      fullPlaceHolder: false,
      customTheme: createTheme({})
    };
  }

  componentDidMount() {
    this.setState({
       customTheme : createTheme({
        palette: {
          primary: {
            main: `rgb(${this.props.colorCodeRgb})`,
            contrastText: `rgb(${this.props.colorCodeRgb})`  // Assuming you want the text to be the same color
          },
          secondary: {
            main: `rgb(${this.props.colorCodeRgb})`,
            contrastText: `rgb(${this.props.colorCodeRgb})`  // Assuming you want the text to be the same color
          },
          outOfFocus: {
            main: `rgba(${this.props.colorCodeRgb}, 0.5)` // Adjusting the alpha value for out-of-focus effect
          },
          common:{
            black: `rgb(${this.props.colorCodeRgb})`
          }
        }
      })
    });

    const platform = PlatForm.os.family;
    if (platform === 'Windows') {
      this.setState({ fullPlaceHolder: true });
    }

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const placeId = params.get('placeId');
    if (placeId != null) {
      this.getPlaceDetails(placeId, (place) => {
        this.setState({ address: place });
      });
    }
  }

  handleInputChange = (event, { newValue }) => {
    this.setState({
      address: newValue
    });
  };

  fetchData = async (searchTerm) => {
    try {
      const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${searchTerm}&limit=5`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const options = data.features.map((feature) => ({
        label: feature.properties.label,
        value: feature,
      }));
      this.setState({ options });
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.fetchData(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({ options: [] });
  };

  getSuggestionValue = (suggestion) => suggestion.label;

  renderSuggestion = (suggestion) => (
    <div className='m-0'>{suggestion.label}</div>
  );

  renderInputComponent = (inputProps) => (
    <TextField
      {...inputProps}
      classes={{ root: 'my-custom-input-color-suggestion' }}
      label={  this.state.fullPlaceHolder
        ? 'Entrez votre adresse pour localiser votre maison par satellite'
        : 'Entrez votre adresse pour localiser votre maison'}
      fullWidth
    />

  );

  onSuggestionSelected = (event, { suggestion }) => {
    this.placeSelectedHandler(suggestion.value);
    console.log(suggestion.value.properties.label);
  };

  placeSelectedHandler = (place) => {
    if (place && place.properties) {
      this.setState({ address: place.properties.label });
      let postalCode = place.properties.postcode;
      let isValidPostalCode = this.state.arrayOfAvaPostalCode[postalCode.substring(0, 2)];
      let isValidPostal = isValidPostalCode === "Yes";
      this.props.parentHandleInput({
        latLong: [place.geometry.coordinates[1], place.geometry.coordinates[0]],
        placeSelected: true,
        address: place.properties.name,
        postalCode: place.properties.postcode,
        isValidPostal: isValidPostal,
        city: place.properties.city
      });
    }
  };

  render() {
    const inputProps = {

      value: this.state.address,
      onChange: this.handleInputChange
    };

    return (
      <ThemeProvider theme={this.state.customTheme}>
        <Autosuggest
          suggestions={this.state.options}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          renderInputComponent={this.renderInputComponent}
          theme={{
            container: 'autosuggest-container',
            suggestionsContainerOpen: 'suggestions-container-open',
            suggestion: 'suggestion',
            suggestionHighlighted: 'suggestion-highlighted',
          }}
        />
      </ThemeProvider>
    );
  }
}

export default AutoCompleteMain;
